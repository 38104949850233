import { DndContext, DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PickListItem, PickList } from '../../models/Picklist';
import PickListService from '../../services/PickListService';
import { ContextMenuItem } from '../shared/ContextMenu';
import DataRow from '../shared/data-grid/DataRow';
import Button, { ButtonType } from '../shared/form-control/Button';
import { SearchInput } from '../shared/form-control/SearchInput';
import InfoIcon from '../shared/icon/InfoIcon';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import StandardModal from '../shared/modal/variants/StandardModal';
import LanguageUtils from '../../utils/LanguageUtils';
import { Translations } from '../../models/Translation';
import TranslatableInput from '../shared/form-control/TranslatableInput';
import { useCurrentClient } from '../../global-state/Clients';

type PicklistEditorProps = {
  picklist: PickList;
  onItemChanged?: () => void;
  onSort?: (newSortOrder: PickListItem[]) => void;
  buttonWrapper?: FC | keyof JSX.IntrinsicElements;
};

const PicklistItemEditor: FC<PicklistEditorProps> = (props) => {
  const { t } = useTranslation('organisation');
  const { picklist, onItemChanged, onSort, buttonWrapper: Wrapper = 'div' } = props;

  const [searchPhrase, setSearchPhrase] = useState('');
  const [editing, setEditing] = useState<Record<string, PickListItem>>({});
  const [selectedItemId, setSelectedItemId] = useState('');
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showNewPicklistItemModal, setShowNewPicklistItemModal] = useState(false);
  const [newPickListItemTranslations, setNewPickListItemTranslations] = useState<Translations>({});
  const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);
  const client = useCurrentClient((x) => x.value);

  const isClientSpecific = useMemo(() => {
    return client !== null;
  }, [client]);

  const filteredItems = useMemo(() => {
    const searchInputLower = searchPhrase.toLowerCase();
    return picklist?.items.filter(
      (item) => !searchPhrase || LanguageUtils.getTranslation('name', item.translations)?.toLowerCase()?.indexOf(searchInputLower) > -1,
    );
  }, [picklist, searchPhrase]);

  const onEditTranslations = (itemId: string, value: Translations) => {
    setEditing((prev) => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        translations: value,
      },
    }));
  };

  const addItem = () => {
    if (!isClientSpecific) {
      PickListService.addItem(picklist.id, newPickListItemTranslations).then(() => {
        onItemChanged && onItemChanged();
        setNewPickListItemTranslations({});
      });
    } else {
      PickListService.addCustomItem(client?.id || '', picklist.id, newPickListItemTranslations).then(() => {
        onItemChanged && onItemChanged();
        setNewPickListItemTranslations({});
      });
    }
  };

  const remove = () => {
    PickListService.deleteItem(selectedItemId).then(() => {
      onItemChanged && onItemChanged();
    });
  };

  const disable = () => {
    if (client) {
      PickListService.disableItem(selectedItemId).then(() => {
        onItemChanged && onItemChanged();
      });
    }
  };

  const enable = (itemId: string) => {
    if (client) {
      PickListService.enable(itemId).then(() => {
        onItemChanged && onItemChanged();
      });
    }
  };

  const updateItem = (itemId: string) => {
    PickListService.updateItem(itemId, editing[itemId]).then(() => {
      onItemChanged && onItemChanged();
      setEditing((prev) => {
        const { [itemId]: _, ...rest } = prev;
        return rest;
      });
    });
  };

  const cancelEditing = (itemId: string) => {
    setEditing((prev) => {
      const { [itemId]: _, ...rest } = prev;
      return rest;
    });
  };

  const contextItemsFor = (item: PickListItem): ContextMenuItem[] => {
    const items = [];

    if (!editing[item.id]) {
      if (item.isCustom || !isClientSpecific) {
        items.push({
          title: t('picklists.context-menu.edit'),
          onClick: () => setEditing((prev) => ({ ...prev, [item.id]: item })),
        });
      }
    }

    if (!isClientSpecific || item.isCustom) {
      items.push({
        title: t('picklists.context-menu.remove'),
        onClick: () => {
          setSelectedItemId(item.id);
          setShowRemoveModal(true);
        },
      });
    } else {
      item.isDisabled
        ? items.push({
            title: t('picklists.context-menu.enable'),
            onClick: () => {
              enable(item.id);
            },
          })
        : items.push({
            title: t('picklists.context-menu.disable'),
            onClick: () => {
              setSelectedItemId(item.id);
              setShowDisableModal(true);
            },
          });
    }

    return items;
  };

  const itemIds = useMemo(() => filteredItems.map((x) => x.id), [filteredItems]);

  const getIndex = useCallback((id: UniqueIdentifier) => picklist?.items.findIndex((x) => x.id === id) ?? -1, [picklist?.items]);

  const onDragStart = useCallback((event: DragStartEvent) => {
    if (!event.active) {
      return;
    }

    setActiveDragId(event.active.id);
  }, []);

  const onDragEnd = (event: DragEndEvent) => {
    setActiveDragId(null);
    if (isClientSpecific || !picklist) {
      return;
    }

    const activeIndex = activeDragId ? getIndex(activeDragId) : -1;
    const overIndex = event.over ? getIndex(event.over.id) : -1;
    if (activeIndex !== overIndex) {
      const newOrder = arrayMove(picklist.items, activeIndex, overIndex);
      onSort && onSort(newOrder);
    }
  };
  return (
    <>
      <Wrapper>
        <div className="flex justify-between gap-4">
          <div className="w-64">
            <SearchInput
              data-cy="picklist-item-search"
              placeholder={t('picklists.inputs.search')}
              value={searchPhrase}
              onChange={(e) => setSearchPhrase(e.target.value)}
            />
          </div>
          <Button type={ButtonType.PRIMARY} onClick={() => setShowNewPicklistItemModal(true)}>
            {t('picklists.buttons.add-new-item')}
          </Button>
        </div>
      </Wrapper>

      {!!filteredItems?.length && (
        <div className="flex py-4">
          {!isClientSpecific && <div className="w-12"></div>}
          <div className="w-1/4">{t('picklists.list.heading.name')}</div>
          <div className="flex-grow">{t('picklists.list.heading.description')}</div>
        </div>
      )}
      {!filteredItems?.length && (
        <div data-cy="picklist-items-empty" className="flex h-full justify-center">
          <div className="my-32 text-center">
            <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
            <div className="text-dpm-20 mt-8">
              {t('picklists.items-empty')}
              <div className="mt-8">
                <Button type={ButtonType.PRIMARY} onClick={() => setShowNewPicklistItemModal(true)}>
                  {t('picklists.buttons.add-new-item')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          <div>
            {filteredItems?.map((item) => (
              <DataRow
                key={item.id}
                data-cy={`picklist-item-${item.id}`}
                disabled={item.isDisabled}
                contextMenuItems={contextItemsFor(item)}
                isSortingDisabled={isClientSpecific}
                sortableId={item.id}
              >
                <div className="flex flex-grow items-center justify-between gap-4">
                  {editing[item.id] ? (
                    <>
                      <div className="w-1/4 font-medium">
                        <TranslatableInput
                          translationKey="name"
                          translations={editing[item.id].translations}
                          onTranslationsChange={(value) => onEditTranslations(item.id, value)}
                          data-cy={`picklist-item-name-edit-${item.id}`}
                          placeholder={LanguageUtils.getTranslation('name', editing[item.id].translations) || t('picklists.inputs.item-name')}
                        />
                      </div>
                      <div className="flex-grow">
                        <TranslatableInput
                          inputType="multiline"
                          translationKey="description"
                          translations={editing[item.id].translations}
                          onTranslationsChange={(value) => onEditTranslations(item.id, value)}
                          data-cy={`picklist-item-description-edit-${item.id}`}
                          placeholder={
                            LanguageUtils.getTranslation('description', editing[item.id].translations) || t('picklists.inputs.item-description')
                          }
                          rows={1}
                          dynamicHeight
                        />
                      </div>
                      <div className="flex flex-shrink-0 gap-4">
                        <Button data-cy={`picklist-item-save-${item.id}`} type={ButtonType.PRIMARY} onClick={() => updateItem(item.id)}>
                          {t('picklists.buttons.save')}
                        </Button>
                        <Button data-cy="picklist-item-cancel-edit" type={ButtonType.SECONDARY} onClick={() => cancelEditing(item.id)}>
                          {t('picklists.buttons.cancel')}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={`text-color-3 w-1/4 flex-shrink-0 break-all sm:font-medium`}>
                        {LanguageUtils.getTranslation('name', item.translations) || (
                          <span className="text-gray-3">{LanguageUtils.getTranslation('name', item.translations, 'en')}</span>
                        )}
                      </div>
                      <div className={`text-color-3 flex-grow break-all font-medium`}>
                        {LanguageUtils.getTranslation('description', item.translations) || (
                            <span className="text-gray-3">
                              {LanguageUtils.getTranslation('description', item.translations, 'en') || t('picklists.inputs.item-description')}
                            </span>
                          ) ||
                          t('picklists.inputs.item-description')}
                      </div>
                      <div className="flex flex-shrink-0 items-center gap-4">
                        {item.isCustom && <div className="text-color-3 font-medium">{t('picklists.custom')}</div>}
                        {item.isDisabled && <div className="text-color-3 font-medium">{t('picklists.disabled')}</div>}
                      </div>
                    </>
                  )}
                </div>
              </DataRow>
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <ModalContext.Provider value={{ open: showRemoveModal, modalWidth: 'w-2/5', onClose: () => setShowRemoveModal(false) }}>
        <ConfirmationModal
          title={t('picklists.remove-modal.heading')}
          description={t('picklists.remove-modal.text')}
          cancelText={t('picklists.remove-modal.buttons.cancel')}
          confirmText={t('picklists.remove-modal.buttons.remove')}
          onCancel={() => {
            setShowRemoveModal(false);
            setSelectedItemId('');
          }}
          onConfirm={() => {
            setShowRemoveModal(false);
            remove();
          }}
          alt
        />
      </ModalContext.Provider>

      <ModalContext.Provider value={{ open: showDisableModal, modalWidth: 'w-2/5', onClose: () => setShowDisableModal(false) }}>
        <ConfirmationModal
          title={t('picklists.disable-modal.heading')}
          description={t('picklists.disable-modal.text')}
          cancelText={t('picklists.disable-modal.buttons.cancel')}
          confirmText={t('picklists.disable-modal.buttons.disable')}
          onCancel={() => {
            setShowDisableModal(false);
            setSelectedItemId('');
          }}
          onConfirm={() => {
            setShowDisableModal(false);
            disable();
          }}
          alt
        />
      </ModalContext.Provider>

      <ModalContext.Provider value={{ open: showNewPicklistItemModal, modalWidth: 'w-2/5', onClose: () => setShowNewPicklistItemModal(false) }}>
        <StandardModal
          title={t('picklists.new-item-modal.heading')}
          cancelButtonTitle={t('picklists.new-item-modal.buttons.cancel')}
          confirmButtonTitle={t('picklists.new-item-modal.buttons.add')}
          onCancelClick={() => {
            setShowNewPicklistItemModal(false);
            setNewPickListItemTranslations({});
          }}
          onConfirmClick={() => {
            addItem();
            setShowNewPicklistItemModal(false);
          }}
          confirmDisabled={!LanguageUtils.getTranslation('name', newPickListItemTranslations).length}
        >
          <TranslatableInput
            translationKey="name"
            translations={newPickListItemTranslations}
            onTranslationsChange={(value) => setNewPickListItemTranslations(value)}
            data-cy="new-picklist-item-name"
            label={t('picklists.inputs.item-name')}
            placeholder={t('picklists.inputs.item-name')}
            maxLength={150}
          />
          <TranslatableInput
            inputType="multiline"
            translationKey="description"
            translations={newPickListItemTranslations}
            onTranslationsChange={(value) => setNewPickListItemTranslations(value)}
            data-cy="new-picklist-item-description"
            label={t('picklists.inputs.item-description')}
            placeholder={t('picklists.inputs.item-description')}
            maxLength={512}
          />
        </StandardModal>
      </ModalContext.Provider>
    </>
  );
};

export default PicklistItemEditor;
